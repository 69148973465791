<template>
  <div class="index">
    <h1>Sistema Hospitalario Lander</h1>
    <div class="flex mt-5">
      <router-link to="/login" class="btnindex">
        <Button label="Iniciar Sesión" icon="pi pi-user" />
      </router-link>
    </div>
  </div>
</template>
<style>
.index {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnindex {
  text-decoration: none;
}
</style>
